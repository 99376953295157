.pswp-gallery {
  flex-wrap: wrap;
  gap: 15px;
  display: flex;
}

.pswp-gallery > a {
  cursor: pointer;
  filter: drop-shadow(6px 6px 3px #000);
  height: 300px;
  position: relative;
}

.pswp-gallery a img {
  object-fit: cover;
  vertical-align: middle;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.pswp {
  --pswp-bg: #282828;
}

.exif-table {
  padding: 0;
  font-size: .7em;
  line-height: 1.3em;
}

.exif-table tr th {
  text-align: left;
  padding-top: .6em;
  font-weight: bold;
}

.exif-description {
  margin-top: 0;
  margin-bottom: .6em;
  padding: 0;
  font-size: .9em;
  line-height: 1em;
}

.exif-headline {
  color: #fff;
  margin-bottom: .5em;
}
/*# sourceMappingURL=index.5186402c.css.map */
