.pswp-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.pswp-gallery > a {
  height: 300px;
  cursor: pointer;
  position: relative;
  filter: drop-shadow(6px 6px 3px black);
}

.pswp-gallery a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 6px;
}

.pswp {
  --pswp-bg: #282828;
}

.exif-table {
  font-size: 0.7em;
  line-height: 1.3em;
  padding: 0;
}

.exif-table tr th {
  font-weight: bold;
  text-align: left;
  padding-top: 0.6em;
}

.exif-description {
  padding: 0;
  line-height: 1.0em;
  margin-top: 0;
  margin-bottom: 0.6em;
  font-size: 0.9em;
}

.exif-headline {
  color: white;
  margin-bottom: 0.5em;
}
